/*@tailwind preflight;*/

.bg-transparent {
  background-color: transparent
}

.bg-red {
  --bg-opacity: 1;
  background-color: #E3342F;
  background-color: rgba(227, 52, 47, var(--bg-opacity))
}

.bg-orange {
  --bg-opacity: 1;
  background-color: #F6993F;
  background-color: rgba(246, 153, 63, var(--bg-opacity))
}

.bg-yellow {
  --bg-opacity: 1;
  background-color: #FFED4A;
  background-color: rgba(255, 237, 74, var(--bg-opacity))
}

.bg-green {
  --bg-opacity: 1;
  background-color: #38C172;
  background-color: rgba(56, 193, 114, var(--bg-opacity))
}

.bg-teal {
  --bg-opacity: 1;
  background-color: #4DC0B5;
  background-color: rgba(77, 192, 181, var(--bg-opacity))
}

.bg-blue {
  --bg-opacity: 1;
  background-color: #3490DC;
  background-color: rgba(52, 144, 220, var(--bg-opacity))
}

.bg-purple {
  --bg-opacity: 1;
  background-color: #9561E2;
  background-color: rgba(149, 97, 226, var(--bg-opacity))
}

.bg-red-light {
  --bg-opacity: 1;
  background-color: #EF5753;
  background-color: rgba(239, 87, 83, var(--bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.rounded-2 {
  border-radius: .2rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-none {
  border-radius: 0
}

.rounded {
  border-radius: .4rem
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-0 {
  border-width: 0
}

.border-1 {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-t-1 {
  border-top-width: 1px
}

.border-r-1 {
  border-right-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-l-1 {
  border-left-width: 1px
}

.cursor-auto {
  cursor: auto
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-no-wrap {
  flex-wrap: nowrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.float-right {
  float: right
}

.font-200 {
  font-weight: 200
}

.font-300 {
  font-weight: 300
}

.font-400 {
  font-weight: 400
}

.font-600 {
  font-weight: 600
}

.font-700 {
  font-weight: 700
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-112 {
  height: 11.2rem
}

.h-128 {
  height: 12.8rem
}

.h-148 {
  height: 14.8rem
}

.h-168 {
  height: 16.8rem
}

.h-184 {
  height: 18.4rem
}

.h-200 {
  height: 20rem
}

.h-216 {
  height: 21.6rem
}

.h-224 {
  height: 22.4rem
}

.h-288 {
  height: 28.8rem
}

.h-full {
  height: 100%
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-17 {
  font-size: 1.7rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-32 {
  font-size: 3.2rem
}

.text-40 {
  font-size: 4rem
}

.text-56 {
  font-size: 5.6rem
}

.text-72 {
  font-size: 7.2rem
}

.text-96 {
  font-size: 9.6rem
}

.leading-none {
  line-height: 1
}

.leading-normal {
  line-height: 1.5
}

.m-16 {
  margin: 1.6rem
}

.m-32 {
  margin: 3.2rem
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

.mx-10 {
  margin-left: 1.0rem;
  margin-right: 1.0rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

.my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mb-0 {
  margin-bottom: 0
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mt-6 {
  margin-top: 0.6rem
}

.mr-6 {
  margin-right: 0.6rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mr-24 {
  margin-right: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mb-28 {
  margin-bottom: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mr-32 {
  margin-right: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.ml-32 {
  margin-left: 3.2rem
}

.mt-40 {
  margin-top: 4rem
}

.mb-40 {
  margin-bottom: 4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-sm {
  max-width: 48rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-xl {
  max-width: 96rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-3xl {
  max-width: 128rem
}

.max-w-4xl {
  max-width: 144rem
}

.max-w-full {
  max-width: 100%
}

.min-h-12 {
  min-height: 1.2rem
}

.min-h-16 {
  min-height: 1.6rem
}

.min-h-24 {
  min-height: 2.4rem
}

.min-h-28 {
  min-height: 2.8rem
}

.min-h-40 {
  min-height: 4rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-80 {
  min-height: 8rem
}

.min-h-112 {
  min-height: 11.2rem
}

.min-h-128 {
  min-height: 12.8rem
}

.min-h-148 {
  min-height: 14.8rem
}

.min-h-184 {
  min-height: 18.4rem
}

.min-h-216 {
  min-height: 21.6rem
}

.min-h-224 {
  min-height: 22.4rem
}

.min-w-0 {
  min-width: 0
}

.min-w-20 {
  min-width: 2rem
}

.min-w-24 {
  min-width: 2.4rem
}

.min-w-28 {
  min-width: 2.8rem
}

.min-w-32 {
  min-width: 3.2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-56 {
  min-width: 5.6rem
}

.min-w-80 {
  min-width: 8rem
}

.min-w-92 {
  min-width: 9.2rem
}

.min-w-112 {
  min-width: 11.2rem
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.outline-none {
  outline: 0
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-4 {
  padding: 0.4rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-28 {
  padding: 2.8rem
}

.p-36 {
  padding: 3.6rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-28 {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.pt-0 {
  padding-top: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.25rem
}

.pr-1 {
  padding-right: 0.25rem
}

.pl-1 {
  padding-left: 0.25rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pb-10 {
  padding-bottom: 1.0rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pr-20 {
  padding-right: 2rem
}

.pb-20 {
  padding-bottom: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pt-28 {
  padding-top: 2.8rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pb-36 {
  padding-bottom: 3.6rem
}

.pt-40 {
  padding-top: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pr-64 {
  padding-right: 6.4rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pr-72 {
  padding-right: 7.2rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.right-0 {
  right: 0
}

.left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
}

.shadow-none {
  box-shadow: none
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-white {
  --text-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-gray-600 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity))
}

.text-red {
  --text-opacity: 1;
  color: #E3342F;
  color: rgba(227, 52, 47, var(--text-opacity))
}

.text-orange {
  --text-opacity: 1;
  color: #F6993F;
  color: rgba(246, 153, 63, var(--text-opacity))
}

.text-yellow {
  --text-opacity: 1;
  color: #FFED4A;
  color: rgba(255, 237, 74, var(--text-opacity))
}

.text-green {
  --text-opacity: 1;
  color: #38C172;
  color: rgba(56, 193, 114, var(--text-opacity))
}

.text-blue {
  --text-opacity: 1;
  color: #3490DC;
  color: rgba(52, 144, 220, var(--text-opacity))
}

.text-purple {
  --text-opacity: 1;
  color: #9561E2;
  color: rgba(149, 97, 226, var(--text-opacity))
}

.text-blue-dark {
  --text-opacity: 1;
  color: #2779BD;
  color: rgba(39, 121, 189, var(--text-opacity))
}

.uppercase {
  text-transform: uppercase
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.hover\:underline:hover {
  text-decoration: underline
}

.select-text {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre-line {
  white-space: pre-line
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-1 {
  width: 0.25rem
}

.w-16 {
  width: 1.6rem
}

.w-32 {
  width: 3.2rem
}

.w-36 {
  width: 3.6rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-148 {
  width: 14.8rem
}

.w-192 {
  width: 19.2rem
}

.w-200 {
  width: 20rem
}

.w-224 {
  width: 22.4rem
}

.w-256 {
  width: 25.6rem
}

.w-288 {
  width: 28.8rem
}

.w-auto {
  width: auto
}

.w-xs {
  width: 32rem
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-99 {
  z-index: 99
}

.z-9999 {
  z-index: 9999
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@media (min-width: 600px) {

  .sm\:bg-black {
    --bg-opacity: 1;
    background-color: #22292F;
    background-color: rgba(34, 41, 47, var(--bg-opacity))
  }

  .sm\:rounded-4 {
    border-radius: .4rem
  }

  .sm\:block {
    display: block
  }

  .sm\:flex {
    display: flex
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:justify-end {
    justify-content: flex-end
  }

  .sm\:justify-center {
    justify-content: center
  }

  .sm\:h-56 {
    height: 5.6rem
  }

  .sm\:h-112 {
    height: 11.2rem
  }

  .sm\:h-128 {
    height: 12.8rem
  }

  .sm\:h-148 {
    height: 14.8rem
  }

  .sm\:h-176 {
    height: 17.6rem
  }

  .sm\:h-full {
    height: 100%
  }

  .sm\:text-14 {
    font-size: 1.4rem
  }

  .sm\:text-20 {
    font-size: 2rem
  }

  .sm\:text-24 {
    font-size: 2.4rem
  }

  .sm\:my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:mt-8 {
    margin-top: 0.8rem
  }

  .sm\:mb-8 {
    margin-bottom: 0.8rem
  }

  .sm\:mt-10 {
    margin-top: 1.0rem
  }

  .sm\:mr-12 {
    margin-right: 1.2rem
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:mr-16 {
    margin-right: 1.6rem
  }

  .sm\:ml-16 {
    margin-left: 1.6rem
  }

  .sm\:mb-24 {
    margin-bottom: 2.4rem
  }

  .sm\:mt-32 {
    margin-top: 3.2rem
  }

  .sm\:mb-32 {
    margin-bottom: 3.2rem
  }

  .sm\:mb-40 {
    margin-bottom: 4rem
  }

  .sm\:mb-48 {
    margin-bottom: 4.8rem
  }

  .sm\:max-w-288 {
    max-width: 28.8rem
  }

  .sm\:max-w-2xl {
    max-width: 112rem
  }

  .sm\:min-h-112 {
    min-height: 11.2rem
  }

  .sm\:min-h-128 {
    min-height: 12.8rem
  }

  .sm\:min-h-148 {
    min-height: 14.8rem
  }

  .sm\:min-w-24 {
    min-width: 2.4rem
  }

  .sm\:min-w-40 {
    min-width: 4rem
  }

  .sm\:min-w-64 {
    min-width: 6.4rem
  }

  .sm\:min-w-96 {
    min-width: 9.6rem
  }

  .sm\:min-w-112 {
    min-width: 11.2rem
  }

  .sm\:min-w-128 {
    min-width: 12.8rem
  }

  .sm\:min-w-168 {
    min-width: 16.8rem
  }

  .sm\:min-w-184 {
    min-width: 18.4rem
  }

  .sm\:min-w-208 {
    min-width: 20.8rem
  }

  .sm\:min-w-256 {
    min-width: 25.6rem
  }

  .sm\:min-w-320 {
    min-width: 32rem
  }

  .sm\:min-w-352 {
    min-width: 35.2rem
  }

  .sm\:opacity-25 {
    opacity: 0.25
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-28 {
    padding: 2.8rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm\:py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem
  }

  .sm\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .sm\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  .sm\:pr-0 {
    padding-right: 0
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pt-6 {
    padding-top: 0.6rem
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem
  }

  .sm\:pl-16 {
    padding-left: 1.6rem
  }

  .sm\:pt-36 {
    padding-top: 3.6rem
  }

  .sm\:pb-36 {
    padding-bottom: 3.6rem
  }

  .sm\:pb-48 {
    padding-bottom: 4.8rem
  }

  .sm\:w-160 {
    width: 16rem
  }

  .sm\:w-360 {
    width: 36rem
  }

  .sm\:w-384 {
    width: 38.4rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-full {
    width: 100%
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:items-center {
    align-items: center
  }

  .md\:justify-center {
    justify-content: center
  }

  .md\:flex-1 {
    flex: 1 1 0%
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:h-200 {
    height: 20rem
  }

  .md\:h-224 {
    height: 22.4rem
  }

  .md\:h-full {
    height: 100%
  }

  .md\:text-20 {
    font-size: 2rem
  }

  .md\:text-24 {
    font-size: 2.4rem
  }

  .md\:text-40 {
    font-size: 4rem
  }

  .md\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:mt-4 {
    margin-top: 0.4rem
  }

  .md\:mt-8 {
    margin-top: 0.8rem
  }

  .md\:mb-8 {
    margin-bottom: 0.8rem
  }

  .md\:mt-16 {
    margin-top: 1.6rem
  }

  .md\:mb-16 {
    margin-bottom: 1.6rem
  }

  .md\:mr-24 {
    margin-right: 2.4rem
  }

  .md\:mt-28 {
    margin-top: 2.8rem
  }

  .md\:mr-32 {
    margin-right: 3.2rem
  }

  .md\:mr-48 {
    margin-right: 4.8rem
  }

  .md\:max-w-320 {
    max-width: 32rem
  }

  .md\:max-w-lg {
    max-width: 80rem
  }

  .md\:max-w-2xl {
    max-width: 112rem
  }

  .md\:min-h-48 {
    min-height: 4.8rem
  }

  .md\:min-w-lg {
    min-width: 80rem
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-28 {
    padding: 2.8rem
  }

  .md\:p-32 {
    padding: 3.2rem
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-84 {
    padding: 8.4rem
  }

  .md\:py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem
  }

  .md\:pt-1 {
    padding-top: 0.25rem
  }

  .md\:pb-8 {
    padding-bottom: 0.8rem
  }

  .md\:pt-20 {
    padding-top: 2rem
  }

  .md\:pt-24 {
    padding-top: 2.4rem
  }

  .md\:pr-24 {
    padding-right: 2.4rem
  }

  .md\:pt-28 {
    padding-top: 2.8rem
  }

  .md\:pr-32 {
    padding-right: 3.2rem
  }

  .md\:pb-32 {
    padding-bottom: 3.2rem
  }

  .md\:pb-36 {
    padding-bottom: 3.6rem
  }

  .md\:pl-36 {
    padding-left: 3.6rem
  }

  .md\:pb-40 {
    padding-bottom: 4rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-right {
    text-align: right
  }

  .md\:w-64 {
    width: 6.4rem
  }

  .md\:w-136 {
    width: 13.6rem
  }

  .md\:w-184 {
    width: 18.4rem
  }

  .md\:w-320 {
    width: 32rem
  }

  .md\:w-400 {
    width: 40rem
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-md {
    width: 64rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.33333%
  }

  .md\:w-2\/3 {
    width: 66.66667%
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:mr-12 {
    margin-right: 1.2rem
  }

  .lg\:max-w-xl {
    max-width: 96rem
  }

  .lg\:max-w-2xl {
    max-width: 112rem
  }

  .lg\:max-w-3xl {
    max-width: 128rem
  }

  .lg\:max-w-5xl {
    max-width: 160rem
  }

  .lg\:p-36 {
    padding: 3.6rem
  }

  .lg\:p-112 {
    padding: 11.2rem
  }

  .lg\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .lg\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .lg\:py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem
  }

  .lg\:px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pb-0 {
    padding-bottom: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }

  .lg\:pb-8 {
    padding-bottom: 0.8rem
  }

  .lg\:pl-20 {
    padding-left: 2rem
  }

  .lg\:pb-36 {
    padding-bottom: 3.6rem
  }

  .lg\:pr-48 {
    padding-right: 4.8rem
  }

  .lg\:w-lg {
    width: 80rem
  }
}

@media (min-width: 1920px) {

  .xl\:flex-row {
    flex-direction: row
  }

  .xl\:items-end {
    align-items: flex-end
  }

  .xl\:h-128 {
    height: 12.8rem
  }

  .xl\:h-256 {
    height: 25.6rem
  }

  .xl\:text-48 {
    font-size: 4.8rem
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .xl\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .xl\:mt-0 {
    margin-top: 0
  }

  .xl\:mr-0 {
    margin-right: 0
  }

  .xl\:ml-8 {
    margin-left: 0.8rem
  }

  .xl\:max-w-4xl {
    max-width: 144rem
  }

  .xl\:min-h-0 {
    min-height: 0
  }

  .xl\:px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem
  }

  .xl\:w-256 {
    width: 25.6rem
  }

  .xl\:w-xl {
    width: 96rem
  }
}
